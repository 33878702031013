import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import Scrollbar from 'smooth-scrollbar';
import {LeavemanagementService} from "../../../../shared/services/leavemanagementservice";
import { constants } from '../../../../shared/services/constants';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {Router} from 'aurelia-router';
import {LoginService} from "../../../../shared/services/loginservice";

@inject(DialogController,ProfileService,JwtService,LeavemanagementService,ValidationControllerFactory,Router,LoginService)
export class ApproveCompOff {
    isDragging = false;
    dialog;
    static inject = [Element];
    pageSize = 10;
    topicsPageSize = 10;
    OnlineTopic = [];
    isLoading = false;
    categoryid = constants.compoffworkflowcategoryid;

    constructor(controller, ProfileService, JwtService, LeavemanagementService, ValidationControllerFactory,loginservice,router) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.leavemanagementService = LeavemanagementService;
        this.empid = this.jwtService.getEmployeeId();
        this.controllerFactory = ValidationControllerFactory.createForCurrentScope();
        this.router = router;
        this.loginservice = loginservice;
        ValidationRules
            .ensure('Comments').required().withMessage('Please enter comments.')
            .on(this);
    }

    activate(viewData) {
        this.compofff = viewData;
        this.CompOffID = viewData.comp;
        this.Status = viewData.Status;
        this.ActionName = viewData.action;
        console.log(this.ActionName);
    }

    attached() {
        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
        this.leavemanagementService.GetViewCompOffDetails(this.CompOffID).then(data => {
            this.compoff = data;
        });
        this.leavemanagementService.GetAdditionalComments(this.CompOffID, this.categoryid)
            .then(data => {
                this.additionalcomments = data;
                this.DocPath = data.DocumentPath;
            });
    }

    dateSort(a, b, sortOrder) {
        let date1 = new Date(a.registered);
        let date2 = new Date(b.registered);
        if (date1 === date2) {
            return 0;
        }
        if (date1 > date2) {
            return 1 * sortOrder;
        }
        return -1 * sortOrder;
    }

    nameLength(row) {
        return row.Type.length;
    }

    cancel() {
        this.controller.cancel();
    }

    openFileDialog() {
        document.getElementById('attachments').click();
    }

    handleFileSelect(files) {
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024; // Maximum file size in bytes (2.5 MB)

        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 4000);

            this.removeFile();
            return;
        }
        else if (file.size <= maxSize) {
            this.file = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentName = file.name;
            reader.onload = () => {
                this.file = reader.result;
            };
        }
    }
    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = ''; // Clear the file input value
        }
    }

    save() {
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    let data = {
                        EmployeeID: this.empid,
                        CompOffID: this.CompOffID,
                        DocumentName: this.DocumentName,
                        DocumentPath: this.file,
                        Comments: this.Comments,
                    };
                    this.leavemanagementService.cancelcompoff(data).then(() => {
                        this.controller.ok(true);
                    });
                }

            });
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
}
