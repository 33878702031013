import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {LeavemanagementService} from "../../../../shared/services/leavemanagementservice";
import {JwtService} from "../../../../shared/services/jwtservice";
import flatpickr from "flatpickr";
import {config} from "../../../../shared/services/config";
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import Scrollbar from "smooth-scrollbar";
import {LoginService} from "../../../../shared/services/loginservice";
import {Router} from 'aurelia-router';

@inject(DialogController,LeavemanagementService,JwtService,ValidationControllerFactory,Router,LoginService,Element)

export class AddNewcompoff {
    isDragging = false;
    dialog;
    static inject = [Element];
    pageSize = config.grid_PazeSize;
    showEmployeeDropdown = false;
    selectedRecordId = null;
    isLoading = false;
  //  isButtonDisabled = false;

    constructor(controller,LeavemanagementService,JwtService,ValidationControllerFactory,loginservice,router,element) {
        this.controller = controller;
        this.dialogService = DialogController;
        this.leavemanagementService = LeavemanagementService;
        this.jwtService= JwtService;
        this.empid = this.jwtService.getEmployeeId();
        this.locid = this.jwtService.getLocationId();
        this.element = element;
        this.controllerFactory = ValidationControllerFactory.createForCurrentScope();
        this.locid = this.jwtService.getLocationId();
        this.router = router;
        this.loginservice = loginservice;


        //this.employeemasterService=employeemasterService;
        ValidationRules
            .ensure('FromDate').required().withMessage('Comp off date field is required.')
            .ensure('Reason').required().withMessage('Reason for request field is required.')
            .ensure('noofdays').required().withMessage('Number of days field is required.')
            .on(this);

    }
    attached() {
        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
        
        const maxDate = new Date();
        maxDate.setMonth(maxDate.getMonth() + 6);

        this.empid = this.jwtService.getEmployeeId();
        flatpickr(this.FromdatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: maxDate,
            defaultDate:null,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr) {
                this.FromDate = dateStr;
            }
        });

        this.FromcalendarIcon.addEventListener('click', () => {
            this.FromdatePicker._flatpickr.open();
        });

        this.specializationInput.focus();
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
    handleFileSelect(files) {
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024; // Maximum file size in bytes (2.5 MB)

        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 4000);

            this.removeFile();
            return;
        }
        else if (file.size <= maxSize) {
            this.file = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentName = file.name;
            reader.onload = () => {
                this.file = reader.result;
            };
        }

    }
    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = ''; // Clear the file input value
        }
    }
    DateCompoff(){
        this.leavemanagementService.fromDateCompoff(this.empid,this.FromDate).then(data =>
        {
            this.compOffData=data;
            const compoffid=0;
            this.leavemanagementService.OTCompOffDetails(this.FromDate,this.empid,compoffid).then(data =>{
                this.compOffIdData=data;
            })
        });
    }

    save() {
        // if (this.isButtonDisabled) {
        //     return;
        // }
        //this.isButtonDisabled = true;
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    if (this.compOffIdData== '-1') {
                        this.compOffIdData=this.noofdays;
                    }
                    if (this.compOffIdData == '1' || this.compOffIdData == '0.5' || this.compOffIdData == '0.50' || this.compOffIdData =='1.0'){
                        let data = {
                            EmployeeID:  this.empid,
                            DocumentName: this.DocumentName,
                            DocumentPath: this.file,
                            CompOffDate:this.FromDate,
                            Reason:this.Reason,
                            Comments:this.Comments,
                            LocationID :this.locid,
                            NoofDays:this.compOffIdData,
                            //ActionName:this.apply
                        };
                        this.leavemanagementService.savenewcompoff(data).then(() => {
                            this.controller.ok(true);
                        });
                    }
                    else if (this.compOffIdData >1)
                    {
                        this.compOffIdDataErrorMessage = "You are eligible for 1 day comp off." ;
                        this.DateCompoff();
                    }
                    else{
                        // this.noofdays='';
                        this.compOffIdData='';
                        this.compOffIdDataErrorMessage = "Number of days field should be like 0.5,1 etc" ;
                        this.DateCompoff();
                    }
                }
            });
    }
    cancel(){
        this.controller.cancel();
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
}
