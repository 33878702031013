import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import 'flatpickr/dist/flatpickr.min.css';
import * as flatpickr from 'flatpickr';
import { AttendanceService } from '../../../shared/services/attendanceservice';
import { JwtService } from '../../../shared/services/jwtservice';

@inject(DialogController,AttendanceService,JwtService)
export class Openevent {
    isDragging = false;
    dialog;
    static inject = [Element];
  timePickerInstance;
  outtimePickerInstance;
  inputRef;
  uploadedFiles = [];
  timeLabel = 'In Time';
  changedMode = 'true';
  selectedMode = '1';
  pageSize = 5;
  ErrorMessageShow=false;
  isSubmitting = false;

  trackingModes = [
    {key: '1', value: 'In'},
    {key: '2', value: 'Out'}
  ];

  timePickerOptions = {
    enableTime: true, // Enable time for the time picker
    noCalendar: true, // Disable the calendar for the time picker
    dateFormat: 'H:i K',
    closeOnSelect: true,
  };

  constructor(DialogController,AttendanceService,JwtService) {
    this.controller = DialogController;
    this.attendanceService = AttendanceService;
    this.jwtService = JwtService;

    this.EmpID = this.jwtService.getEmployeeId();
  }

  activate(data) {
    this.reqData = data;
    this.Indate = data.Date;
    this.totalDuration = data.TotalDuration;
    this.punchIn = data.InTime;
    this.punchOut = data.OutTime;
    this.InDateTime = this.Indate + ' ' + this.punchIn;
    this.OutDateTime = this.Indate + ' ' + this.punchOut;
  }

  attached() {
    this.attendanceService.GetAttendanceDetailsMobile(this.EmpID,this.Indate).then(data =>{
      this.editData = data;
    });
    this.attendanceService.ChangeRequestGrid(this.EmpID,this.Indate).then(data => {
      this.pendingReqData = data;
    })
    this.fileValidator();
    this.InTimepicker();
    this.OutTimePicker();
  }

  selectMode(mode){
    this.currentMode = mode;
    if(mode == '1'){
      this.timeLabel = "In Time";
      this.changedMode = 'true';
    }else if(mode == '2'){
      this.timeLabel = "Out Time";
      this.changedMode = 'false';
    }
  }

  InTimepicker() {
    this.timePickerOptions = {
      enableTime: true,
      noCalendar: true,
      //dateFormat: 'H:i',
      dateFormat: 'h:i K', 
      defaultDate: this.punchIn,
      time_24hr: false,
      hour12: true,
      onValueUpdate: (selectedDates, dateStr, instance) => {
        // Use onValueUpdate to capture changes when manually entering time
        this.timeValue = this.Indate + ' ' +   dateStr;
      },
    };   
    this.timePickerInstance = flatpickr.default(this.inputRef, this.timePickerOptions);
  }

  OutTimePicker() {
    this.outtimePickerOptions = {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'h:i K', // 12-hour format with AM/PM
      defaultDate: this.punchOut,
      time_24hr: false,
      hour12: true,
      onValueUpdate: (selectedDates, dateStr, instance) => {
        // Use onValueUpdate to capture changes when manually entering time
        this.outtimeValue = this.Indate + ' ' +   dateStr;
      },
    };
  
    this.outtimePickerInstance = flatpickr.default(this.outinputRef, this.outtimePickerOptions);
  }
  

  getDefaultDateTime() {
    const currentDate = this.datePickerInstance.input.value;
    return `${currentDate} ${this.defaultIn}`;
  }

  modeMatcher = (a,b) => {
    return a.key == b.key;
  }

  unbind() {
    if (this.timePickerInstance) {
      this.timePickerInstance.destroy();
    }
  }
  
  unbindOut() {
    if (this.outtimePickerInstance) {
      this.outtimePickerInstance.destroy();
    }
  }
  

  openTimePicker() {
    this.timePickerInstance.open();
  }

  openOutTimePicker() {
    if (this.outtimePickerInstance) {
      this.outtimePickerInstance.open();
    }
  }


  openFileDialog() {
    document.getElementById('attachments').click();
   }

   fileValidator(){
    const fileInput = document.querySelector('input[type="file"]');
        const allowedTypes = ['image/jpeg', 'image/png','application/msword','application/pdf'];
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const fileType = file.type;
            if (!allowedTypes.includes(fileType)) {
                this.fileMessage="Only JPEG, PNG, PDF and DOC files are allowed.";
                this.fileValidatorRule = true;
            } else {
                this.fileMessage=null;
                this.fileValidatorRule = false;
            }
        });
   }

   handleFileSelect(files) {
    const file = files[0];
    this.file = file;
    const allowedFormats = ['.jpg', '.png', '.doc', '.pdf'];
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      if (allowedFormats.includes(fileExtension)) {
        const reader = new FileReader();
        reader.onload = (event) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          this.documentFileName = file.name;
          reader.onload = () => {
            this.file = reader.result;
          };
        };
        reader.readAsDataURL(file);
      } else {
        // Handle invalid file format here (e.g., display an error message)
        // console.log('Invalid file format:', file.name);
        this.uploadErrors = [{ error: { message: '' } }];
        
      }
}

openFileDialog() {
    document.getElementById('attachments').click();
}

removeFile() {
    this.file = null;
    this.documentFileName = '';
    const fileInput = document.getElementById('attachments');
    if (fileInput) {
        fileInput.value = ''; // Clear the file input value
    }
}
  Submit(){
    this.isSubmitting = true;
    const attendanceData = {
      InOutIND : this.changedMode,
      InTime : this.timeValue ? this.timeValue : this.InDateTime,
      OutTime : this.outtimeValue ? this.outtimeValue : this.OutDateTime,
      EmployeeID : this.EmpID,
      DocumentPath : this.file,
      DocumentName : this.documentFileName,
      Comments : this.comments,
      PunchInDate :this.Indate   
    };
    this.attendanceService.ValidateAttendance(attendanceData)
      .then(data => {
        this.ErrorMessageShow = true;
        this.message = data;
        if(!data){
          this.attendanceService.AttentendencesWorkflowApproverExist(this.EmpID).then(response => {
            if(response === false){
              this.ErrorMessageShow = true;
              this.message = "Approvers are not configured, please contact admin.";
            }else{
              this.ErrorMessageShow = false;
              this.attendanceService.SaveAttendanceData(attendanceData).then(() => {
                this.controller.ok(true);
              })
            }                        
          })
        }
      });
  }

  cancel() {
    this.controller.cancel();
 }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
}
