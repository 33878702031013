import {inject } from 'aurelia-framework';
import { bindable } from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import { Openevent } from './openevent';
import { JwtService } from '../../../shared/services/jwtservice';
import { AttendanceService } from '../../../shared/services/attendanceservice';
import { DashboardService } from '../../../shared/services/dashboardservice';

@inject(DialogService,JwtService,AttendanceService,DashboardService)
export class Calender {

  @bindable year;
  @bindable month;
  @bindable currentday;
  calendar = [];
  selectedDate = null;
  showMobileData = false;
  attendanceMessages = false;
  daysOfWeek = [
    {week:'Monday',attribute:'M'},
    {week:'Tuesday',attribute:'T'},
    {week:'Wednesday',attribute:'W'},
    {week:'Thursday',attribute:'T'},
    {week:'Friday',attribute:'F'},
    {week:'Saturday',attribute:'S'},
    {week:'Sunday',attribute:'S'}
    ];

  constructor(DialogService,JwtService,AttendanceService,DashboardService){
    this.jwtService = JwtService;
    this.dialogService = DialogService;
    this.attendanceService = AttendanceService;
    this.dashboardService = DashboardService;
    this.EmpId = this.jwtService.getEmployeeId();
  }

  yearChanged() {
    this.generateCalendar();
  }

  monthChanged() {
    this.generateCalendar();
  }

  isWeekend(dayOfWeek) {
    return dayOfWeek === 'Saturday' || dayOfWeek === 'Sunday';
  }

  formatDate(year, month, day) {
    // Ensure that month and day are always two digits
    const formattedMonth = String(month).padStart(2, '0');
    const formattedDay = String(day).padStart(2, '0');
    return `${formattedDay}-${formattedMonth}-${year}`;
  }

  attached(){
    this.dashboardService.GetServerCurrentDate(this.EmpId).then(data => {
      const apiDatetimeStr = data.CurrentDate;
      this.apiDatetime = new Date(apiDatetimeStr);
      this.currentDate = apiDatetime.getDate().toString().padStart(2);
    })
  }
  
  generateCalendar() {
    this.attendanceService.GetMyAttendanceDetails(this.EmpId, this.month, this.year)
      .then(data => {
        this.attendanceDetails = data;
    const date = new Date(this.year, this.month - 1, 1);
    const numDays = new Date(this.year, this.month, 0).getDate();
    const firstDayIndex = (date.getDay() + 6) % 7; // Adjust the starting day index
    this.calendar = [];  
    let row = [];
    for (let i = 0; i < firstDayIndex; i++) {
      row.push('');
    } 
    let dayCount = 1;
    while (dayCount <= numDays) {
      // Create an object to hold the date and its associated data
      const formattedDate = this.formatDate(this.year, this.month, dayCount);
      const dateObj = {
        day: dayCount,
        attendanceData: this.findAttendanceDataForDate(formattedDate),
      };  
      row.push(dateObj); 
      if (row.length === 7) {
        this.calendar.push(row);
        row = [];
      } 
      dayCount++;
    }  
    if (row.length > 0) {
      while (row.length < 7) {
        row.push('');
      }
      this.calendar.push(row);
    }
  });
  }
  
  findAttendanceDataForDate(formattedDate) {
    // Find and return the attendance data for the given date
    return this.attendanceDetails.find(item => item.Date === formattedDate);
  }

  findAttendanceDataForDateMobile(formattedDate) {
    // Find and return the attendance data for the given date
    return this.attendanceDetails.find(item => item.Date === formattedDate);
  }

  isEditAllowed(day) {
    const targetDate = new Date(this.year, this.month - 1, day);   
    // Check if the target date is before the current date
    return targetDate < this.apiDatetime && !this.isSameDate(targetDate, this.apiDatetime);
  }

  isSameDate(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
  

  dateClicked(day) {
    this.showMobileData = true;
    this.selectedDate = day;
    const formattedDate = this.formatDate(this.year, this.month, day);
    const attendanceData = this.findAttendanceDataForDate(formattedDate);
    const dateObj = new Date(this.year, this.month - 1, day);
    const monthName = dateObj.toLocaleString('default', { month: 'long' });
    const dayNumber = dateObj.getDate();
    const yearNumber = dateObj.getFullYear();
    this.formattedDateString = this.getFormattedDate(monthName, dayNumber, yearNumber);
    this.attentendanceDataMobile = attendanceData;
  }


  getFormattedDate(month, day, year) {
    const suffix = this.getDayNumberSuffix(day);
    return `${month} ${day}${suffix}, ${year}`;
  }

  getDayNumberSuffix(day) {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  editEvent(data){
    this.dialogService.open({viewModel:Openevent,model:data})
    .whenClosed((feedback) => {
      if(!feedback.wasCancelled){
      //this.refreshGrids();
      this.ChangeReqSuccessMessage = 'Change request submitted successfully';
      this.attendanceMessages = true;
         setTimeout(() => {this.attendanceMessages = null}, 3000);
      }else{
        //console.log('Controller is cancled');
      }
    });
  }
  
}
