import {inject,bindable} from 'aurelia-framework';
import { JwtService } from '../../../shared/services/jwtservice';
import { AttendanceService } from '../../../shared/services/attendanceservice';
import { App } from '../../../app';
import 'flatpickr/dist/flatpickr.min.css';
import flatpickr from 'flatpickr';
import {SharedState} from "../../../shared/state/sharedstate";
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';

@inject(JwtService,AttendanceService,App,Router,LoginService)
export class Adminattendancesummary {
  @bindable selectedStatus = "ACT";
  year;
  month;

  constructor(JwtService,AttendanceService,App,loginservice,router) {
    this.app = App;
    this.jwtService = JwtService;
    this.attendanceService = AttendanceService;
      this.router = router;
      this.loginservice = loginservice;
    this.EmpId = this.jwtService.getEmployeeId();
  }
  async attached() {
      // if(this.jwtService.getToken() === undefined){
      //     this.loginservice.purgeAuth();
      //     this.router.navigateToRoute('login')
      // }
    this.attendanceMonths = await this.attendanceService.GetAttendanceMonth();
    this.monthOptions = Object.entries(this.attendanceMonths).map(([key, value]) => ({ value, key }));
    this.attendanceYears = await this.attendanceService.GetAttendanceYear();
    this.yearOptions = Object.entries(this.attendanceYears).map(([key, value]) => ({ value, key }));
    this.getDepartments(this.selectedStatus);
  }
  selectedStatusChanged(newValue) {
    this.selectedStatus = newValue;
    this.getDepartments(this.selectedStatus);
  }
  getDepartments(status){
    this.attendanceService.DepartmentDropdown(this.EmpId,status).then(data =>{
        this.filteredDepartments = data;
     })
  }
  selectDepartment(department){
      this.DepartmentID = department.DepartmentId;
    this.getEmployeesDropdown(this.DepartmentID);
  }
  getEmployeesDropdown(departmentID){
    this.attendanceService.GetAllEmployeeNamesDropdown(this.EmpId,this.selectedStatus,departmentID).then(data =>{
        this.filteredEmployees = data;
     });
  }
  selectEmployee(employee){
      this.employeeId = employee.EmployeeID;
  }
  calenderMonthConfig(data){
      this.month = data.key;
  }
  calenderYearConfig(data){
      this.year = data.value.Year;
  }
  Search(){
    const summaryData = {
      strDepartmentID : this.DepartmentID,
      strEmployeeID : this.employeeId,
      EmployeeStatus : this.selectedStatus,
      Year : this.year,
      Month : this.month
    };
    this.attendanceService.GetAdminAttendanceSummary(summaryData).then(response => {
      this.employeeSummaryDetails = response;
    })
  }

}

