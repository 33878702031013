import {inject,bindable, autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import { App } from '../../../../app';
import {DialogService} from 'aurelia-dialog';
import {LeavemanagementService} from "../../../../shared/services/leavemanagementservice";
import {JwtService} from "../../../../shared/services/jwtservice";
import flatpickr from "flatpickr";
 import {AddNewcompoff} from "../addnewcompoff/addnewcompoff";
 import {ViewCompOff} from "../viewcompoff/viewcompoff";
 import {ApproveCompOff} from "../approve/approve"
import * as XLSX from "xlsx";
import {config} from '../../../../shared/services/config';
import {constants} from "../../../../shared/services/constants";
import {LoginService} from "../../../../shared/services/loginservice";

@inject(DialogService, Router, App,LeavemanagementService,JwtService,LoginService)
@autoinject()
export class EmployeeTimeOffDetailsComponent {
    @bindable selectedOption= '';
    isDropdownOpen = false;
    defaultToDate;
    defaultFromDate;
    pageSize = config.grid_PazeSize;
    showEmployeeDropdown = false;
    selectedRecordId = null;
    isLoading = false;
    FavoritesExist = false;
    Successmessages=false;
    sitemapId=constants.mycompoffsitemapid;
    /*statusOptions = [
        { label: 'All', value: 'All', checked: false },
        { label: 'Pending with me', value: 'PEAM', checked: true },
        { label: 'Pending approval', value: 'ACT', checked: true },
        { label: 'Rejected', value: 'REJ', checked: false },
        { label: 'Cancelled', value: 'INA', checked: false },
        { label: 'Approved', value: 'APP', checked: false },
        { label: 'Taken', value: 'Taken', checked: false }
    ];*/
    constructor(dialogService,router, App,LeavemanagementService,JwtService,loginservice,element) {
        this.dialogService = dialogService;
        this.router = router;
        this.app = App;
        this.leavemanagementService = LeavemanagementService;
        this.jwtService= JwtService;
        this.empid = this.jwtService.getEmployeeId();
        this.element = element;
        this.loginservice = loginservice;
    }

    getCurrentRouteURL() {
      const currentInstruction = this.router.currentInstruction;
      if (currentInstruction) {
        const routeURL = currentInstruction.fragment;
        const sidebarListItem = document.querySelector('.sidebar ul li.attendance');
        if (sidebarListItem) {
          const subActiveItems = document.querySelectorAll('.sidebar ul li.sub-active');
          subActiveItems.forEach(item => item.classList.remove('sub-active'));
          const activeLinkItems = document.querySelectorAll('.sidebar ul li.active-link');
          activeLinkItems.forEach(item => item.classList.remove('active-link'));
          sidebarListItem.classList.add('sub-active');
        }
        const sidebarLink = document.querySelector('.sidebar ul li.attendance .sidebar-sub .MyCompOffRequest a');
        if (sidebarLink) {
          sidebarLink.classList.add('active-link');
        }
        return routeURL;
      }
      return '';
    }

    attached() {
        this.getCurrentRouteURL();
        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }

        function formatDateWithLeadingZero(date) {
            const day = date.getDate();
            const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
            const year = date.getFullYear();
            const formattedDay = day.toString().padStart(2, '0');
            const formattedMonth = month.toString().padStart(2, '0');
            return `${formattedDay}-${formattedMonth}-${year}`;
          }

        const fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 4);
        
        this.FDate = formatDateWithLeadingZero(fromDate);
        this.From = this.FDate;

        const toDate = new Date();
        toDate.setMonth(toDate.getMonth() +6);
        
        this.TDate = formatDateWithLeadingZero(toDate);
        this.To = this.TDate;

        // const fromDate = new Date();
        // fromDate.setMonth(fromDate.getMonth() -4);
        // this.FDate = formatDateWithLeadingZero(fromDate);
        // this.From =  this.FDate;
        // const toDate = new Date();
        // toDate.setMonth(toDate.getMonth() + 6);
        // this.TDate = formatDateWithLeadingZero(toDate);
        // this.To= this.TDate;
        flatpickr(this.FromdatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: fromDate,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.FromDate = dateStr;
            }
        });
        this.FromcalendarIcon.addEventListener('click', () => {
            this.FromdatePicker._flatpickr.open();
        });
        flatpickr(this.TodatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: toDate,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.default = dateStr;
            }
        });
        this.TocalendarIcon.addEventListener('click', () => {
            this.TodatePicker._flatpickr.open();
        });
      /*  const selectedStatusList = this.statusOptions
            .filter(status => status.checked)
            .map(status => status.value);*/
        console.log(this.To);
        const timeoff= {
            Fromdate:this.From,
            /*StatusList: selectedStatusList,*/
            Todate:this.To,
            EmployeeID : this.empid,
            // Department: this.departmentId,
        };

        this.leavemanagementService.GetMyCompOffDetails(timeoff).then(data => {
                this.myCompOffDetails = data.List;
               this.isLoading = false;
            console.log(this.myCompOffDetails);
            });

        this.leavemanagementService.GetPersonFavoriteItems(this.empid,this.sitemapId)
            .then(data=>{
                this.FavoritesExist=data;
            });
        sessionStorage.removeItem('activeTab');

        this.addToRecentItems();
    }
    // selectOption(option) {
    //     this.selectedOption = option;
    //     this.isDropdownOpen = false;
    // }

   /* selectAllChanged(event) {
        const isChecked = this.statusOptions[0].checked;
        this.statusOptions.forEach((status, index) => {
            if (index !== 0) {
                status.checked = isChecked;
            }
        });
    }
    handleCheckboxChange(event, status) {
        if (status.value === 'All') {
            this.statusOptions.forEach((statusOption, index) => {
                if (index !== 0) {
                    statusOption.checked = false;
                }
            });
        } else {
            const allChecked = this.statusOptions.slice(1).every(statusOption => statusOption.checked);
            this.statusOptions[0].checked = allChecked;
        }

        //status.checked = event.target.checked;
    }*/


    toggleDropdown($event, employee) {
        //console.log(employee);
        const isDropdownOpen = employee.showDropdown;
        this.$displayData.forEach((item) => {
            item.showDropdown = false;
            item.timeoffshowDropdown = false;
        });
        if (!isDropdownOpen) {
            employee.showDropdown = true;
            employee.timeoffshowDropdown = false;
            document.addEventListener('click', this.closeDropdown);
        }
        $event.stopPropagation();
    }
    closeDropdown = () => {
        this.$displayData.forEach((item) => {
            item.showDropdown = false;
            item.timeoffshowDropdown = false;
        });
        document.removeEventListener('click', this.closeDropdown);
    };
    viewToggleDropdown($event, employee) {
        //console.log(employee);
        const timeoffisDropdownOpen = employee.timeoffshowDropdown;
        this.$displayData.forEach((item) => {
            item.timeoffshowDropdown = false;
            item.showDropdown = false;
        });
        if (!timeoffisDropdownOpen) {
            employee.timeoffshowDropdown = true;
            employee.showDropdown = false;
            document.addEventListener('click', this.viewcloseDropdown);
        }
        $event.stopPropagation();

    }
    viewcloseDropdown = () => {
        this.$displayData.forEach((item) => {
            item.timeoffshowDropdown = false;
            item.showDropdown = false;
        });
        document.removeEventListener('click', this.timeoffcloseDropdown);
    };

        showDialog(CompOffID) {

            /*this.leavemanagementService.GetViewCompOffDetails(CompOffID).then(data=>
            {
                this.compoff=data;
                this.comp=this.compoff;
                console.log(this.comp);
            });*/
            this.dialogService.open({viewModel: ViewCompOff, model: CompOffID});
        }
    ApproveShowDialog(StatusCode,CompOffID,ActionName){

        const statuscode ={
            Status:StatusCode,
            comp:CompOffID,
            action:ActionName
        };
        console.log(statuscode);
        this.dialogService.open({viewModel: ApproveCompOff, model:statuscode })
            .whenClosed((feedback) => {
                if(!feedback.wasCancelled){
                    const timeoff= {
                        Fromdate:this.From,
                        /*StatusList: selectedStatusList,*/
                        Todate:this.To,
                        EmployeeID : this.empid,
                        // Department: this.departmentId,
                    };
                    console.log(timeoff);


                    this.leavemanagementService.GetMyCompOffDetails(timeoff).then(data => {
                        this.myCompOffDetails = data.List;
                        this.isLoading = false;
                        console.log(this.myCompOffDetails);
                        this.messages = "Comp off request cancelled successfully.";
                        this.Successmessages = true;
                        setTimeout(()=>{this.messages=null},3000);
                    });

                }
            });
    }

    cancel() {
        this.controller.cancel();
    }
    Addnewrequestpopup(CompOffID) {

        this.dialogService.open({viewModel: AddNewcompoff, model: CompOffID})
            .whenClosed((feedback) => {
                if(!feedback.wasCancelled){
                    const timeoff= {
                        Fromdate:this.From,
                        /*StatusList: selectedStatusList,*/
                        Todate:this.To,
                        EmployeeID : this.empid,
                        // Department: this.departmentId,
                    };
                    console.log(timeoff);


                    this.leavemanagementService.GetMyCompOffDetails(timeoff).then(data => {
                        this.myCompOffDetails = data.List;
                        this.isLoading = false;
                        console.log(this.myCompOffDetails);
                        this.messages = "Comp off submitted  successfully.";
                        this.Successmessages = true;
                        setTimeout(()=>{this.messages=null},3000);
                    });

                }
            });
    }
    search() {
       /* const filterOpenElements = document.querySelectorAll('.filter-open');
        filterOpenElements.forEach(element => element.classList.remove('filter-open'));
        const selectedStatusList = this.statusOptions
            .filter(status => status.checked)
            .map(status => status.value);*/
        const finalToDate = this.ToDate ? this.ToDate : this.defaultToDate;
        const finalFromDate = this.FromDate ? this.FromDate : this.defaultFromDate;
        console.log(finalToDate);
        if(finalFromDate==undefined){
            this.finalFromDate=this.From;
        }else {
    this.finalFromDate=finalFromDate
        }
        if (finalToDate==undefined){
            this.finalToDate=this.To;
        }
        else {
            this.finalToDate=finalToDate
        }
        const timeoff = {
            Fromdate: this.finalFromDate,
            Todate: this.finalToDate,
            //StatusList: selectedStatusList.includes('All') ? ['All'] : selectedStatusList.filter(status => status !== 'All'),
            EmployeeId : this.empid,
        };
        this.isLoading = true;
        this.leavemanagementService.GetMyCompOffDetails(timeoff)
            .then(data => {
                this.myCompOffDetail = data;
                this.myCompOffDetails = data.List;
                this.isLoading = false;
               });
    }
    exportData() {
        const data = this.myCompOffDetails;
        const filename = 'Compoffdetails';
        this.exportToExcel(data, filename);
    }
    exportToExcel(data, filename) {
        const filteredData = data.map(employee => {
            //console.log(filteredData);
            return {
                'Comp off date': employee.Date,
                'Requested date':employee.RequestDate,
                'Number of days':employee.NoofDays,
                Reason:employee.Reason,
                Status: employee.StatusText,
            };
        });
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        XLSX.writeFile(workbook, `${filename}.xlsx`);
    }
    filterClick() {
        event.preventDefault();
        const clickedDiv = event.target.parentNode;
        clickedDiv.classList.toggle('filter-open');
        const divs = document.querySelectorAll('.filter-open');
        divs.forEach((div) => {
            if (div !== clickedDiv) {
                div.classList.remove('filter-open');
            }
        });
        const handleOutsideClick = (event) => {
            if (!clickedDiv.contains(event.target)) {
                divs.forEach((div) => {
                    div.classList.remove('filter-open');
                });
                document.removeEventListener('click', handleOutsideClick);
            }
        };

        if (clickedDiv.classList.contains('filter-open')) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }
    }
    addToFavourites(){
        this.leavemanagementService.AddToFavourites(this.empid,this.sitemapId).then(data=>
        {
            //this.attached();
            window.location.reload();
        });
    }

    addToRecentItems(){    
        this.leavemanagementService.AddToRecentItems(this.empid,this.sitemapId).then(data=>
         {
          // window.location.reload();

         });
     }

    // resetDepartmentDropdown() {
    //     this.department = ''; // Reset the selected department to empty value
    // }
    //
    // resetEmployesDropdown() {
    //     this.employeeclass = ''; // Reset the selected department to empty value
    // }

    /*resetStatusDropdown() {
        this.statusOptions.forEach((status, index) => {
            if (index === 1 || index === 2) {
                status.checked = true; // Select 'Pending approval' and 'Pending with me'
            } else {
                status.checked = false; // Deselect other statuses
            }
        });

        this.selectedStatusList = this.statusOptions.filter(status => status.checked);

    }*/
    /*removeFilter(Allstatus,status) {
        if (status.label === 'All') {
            this.statusOptions.forEach(option => {
                option.checked = false;
            });
        } else {
            status.checked = false;
            this.statusOptions[0].checked = false;
        }
      }
    get selectedStatuses() {
        return this.statusOptions.filter(status => status.checked);
        }
    resetPage(){
        this.attached();
        console.log(this.From);

    }*/
    resetPage(){
        this.attached();
    }

}
