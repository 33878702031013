import {inject} from 'aurelia-framework';
import { AttendanceService } from "../../../shared/services/attendanceservice";
import { JwtService } from "../../../shared/services/jwtservice";
import $ from 'jquery';
import slick from 'slick-carousel';
import { constants } from '../../../shared/services/constants';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import { LoginService } from '../../../shared/services/loginservice';
import { DashboardService } from '../../../shared/services/dashboardservice';
import {Router} from 'aurelia-router';

@inject(JwtService,AttendanceService,LeavemanagementService,LoginService,Router,DashboardService)
export class Myattendance {

  // year = 2023;
  // month = 7;
  FavoritesExist = false;
  sitemapId=constants.myattendancesitemapid;

  constructor(JwtService,AttendanceService,LeavemanagementService,LoginService,Router,DashboardService){
    this.jwtService = JwtService;
    this.attendanceService = AttendanceService;
    this.leavemanagementService=LeavemanagementService;
    this.router = Router;
    this.loginservice = LoginService;
    this.dashboardService = DashboardService;
    this.EmpId = this.jwtService.getEmployeeId();
   

  }

  monthMatcher = (a,b) => {
    return a.key === b.key
  };

  yearMatcher = (a,b) => {
    return a.Year === b.year
  }

  getCurrentRouteURL() {
      const currentInstruction = this.router.currentInstruction;
      if (currentInstruction) {
        const routeURL = currentInstruction.fragment;
        const sidebarListItem = document.querySelector('.sidebar ul li.attendance');
        if (sidebarListItem) {
          const subActiveItems = document.querySelectorAll('.sidebar ul li.sub-active');
          subActiveItems.forEach(item => item.classList.remove('sub-active'));
          const activeLinkItems = document.querySelectorAll('.sidebar ul li.active-link');
          activeLinkItems.forEach(item => item.classList.remove('active-link'));
          sidebarListItem.classList.add('sub-active');
        }
        const sidebarLink = document.querySelector('.sidebar ul li.attendance .sidebar-sub .Myattendancesummary a');
        if (sidebarLink) {
          sidebarLink.classList.add('active-link');
        }
        return routeURL;
      }
      return '';
    }

  attached() {
    
    this.getCurrentRouteURL();

    this.dashboardService.GetServerCurrentDate(this.EmpId).then(data => {
      const apiDatetimeStr = data.CurrentDate;
      const apiDatetime = new Date(apiDatetimeStr);
      const day = apiDatetime.getDate().toString().padStart(2, "0");
      this.serverMonth = (apiDatetime.getMonth() + 1).toString();
      this.serverYear = apiDatetime.getFullYear().toString();
      this.year = this.year ? this.year : this.serverYear;
      this.month = this.month ? this.month : this.serverMonth;
     
    this.attendanceService.GetAttendanceMonth().then(data=> { 
      this.attendanceMonths= data;
      this.monthOptions = Object.entries(this.attendanceMonths).map(([key, value]) => ({ value, key }));
    })   
    
    this.attendanceService.GetAttendanceYear().then(data=> { this.attendanceYears= data;
      this.yearOptions = Object.entries(this.attendanceYears).map(([key, value]) => ({ value, key }));
    })    
   
    this.attendanceService.GetAttendanceSummary(this.EmpId,this.month,this.year).then(data=> { 
      this.summaryData= data;
      this.workedDays = this.summaryData.WorkedDays;
      this.workedHours = this.summaryData.WorkedHours;
      this.approvedShifts = this.summaryData.ApproveWorkedHours;
    })

  })
    setTimeout(() => {
        $('.attendance-slick').slick({
            dots: false,
            infinite: false,
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1366,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 1280,
                    settings: {
                        arrows: false,
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 666,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        centerPadding:'50px',
                    }
                }
            ]
        });
    }, 1000);

    this.leavemanagementService.GetPersonFavoriteItems(this.EmpId,this.sitemapId)
        .then(data=>{
            this.FavoritesExist=data;
            //console.log(data);
        });
          
        this.addToRecentItems();

  }

  GetSummaryData(month,year){
    this.attendanceService.GetAttendanceSummary(this.EmpId,month,year)
    .then(data => {
      this.summaryData = data;
      this.workedDays = this.summaryData.WorkedDays;
      this.workedHours = this.summaryData.WorkedHours;
      this.approvedShifts = this.summaryData.ApproveWorkedHours;
    })
    
  }

  calenderMonthConfig(data){
    this.month = data.key;
    this.GetSummaryData(this.month,this.year);
  }
  calenderYearConfig(data){
    this.year = data.value.Year;
    this.GetSummaryData(this.month,this.year);
  }

  addToFavourites(){
    this.leavemanagementService.AddToFavourites(this.EmpId,this.sitemapId).then(data=>
     {
         //this.attached();
         window.location.reload();
     });

 }
 
 addToRecentItems(){    
  this.leavemanagementService.AddToRecentItems(this.EmpId,this.sitemapId).then(data=>
   {
    // window.location.reload();

   });
}

}
