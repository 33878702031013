import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import Scrollbar from 'smooth-scrollbar';
import {LeavemanagementService} from "../../../../shared/services/leavemanagementservice";
import { constants } from '../../../../shared/services/constants';
import { config } from '../../../../shared/services/config';
import {LoginService} from "../../../../shared/services/loginservice";
import {Router} from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,LeavemanagementService,LoginService,Router)
export class ViewCompOff{
    isDragging = false;
    dialog;
    static inject = [Element];
    pageSize = 10;
    topicsPageSize=10;
    OnlineTopic = [];
    isLoading = false;
    additionalcomments = [];
    CategoryId=constants.compoffworkflowcategoryid;
    Url=config.img_url;

    constructor(controller, ProfileService,JwtService,LeavemanagementService,loginservice,router) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.leavemanagementService = LeavemanagementService;
        this.empid = this.jwtService.getEmployeeId();
        this.loginservice = loginservice;
        this.router = router;

    }
    activate(viewData){

         this.compoff=viewData;
        console.log(this.compoff);
    }

        attached(){
            this.isLoading = true;
            // if(this.jwtService.getToken() === undefined){
            //     this.loginservice.purgeAuth();
            //     this.router.navigateToRoute('login')
            // }
            this.leavemanagementService.GetViewCompOffDetails(this.compoff).then(data=>
            {
                this.compoff=data;
            });
            this.isLoading = false;
            this.leavemanagementService.GetAdditionalComments(this.compoff,this.CategoryId)
            .then(data =>{
                this.additionalcomments = data;
                this.Files =  this.additionalcomments.map(option => option.DocumentPath);
                if ( this.additionalcomments.map(option => option.DocumentPath)) {
                    this.file =  this.additionalcomments.map(option => option.DocumentPath);
                    this.DocumentName = this.additionalcomments.map(option => option.DocumentPath); // Set the desired file name
                }
            });
        }
        RefreshProfileImage(data){
        this.userPhotoElement = document.querySelector('.rounded-full.dropdown.inline-block');
        this.userPhotoElement.src = data;
    }

    dateSort(a, b, sortOrder) {
        let date1 = new Date(a.registered);
        let date2 = new Date(b.registered);

        if (date1 === date2) {
            return 0;
        }

        if (date1 > date2) {
            return 1 * sortOrder;
        }

        return -1 * sortOrder;
    }
    nameLength(row) {
        return row.Type.length;
    }
    cancel(){
        this.controller.cancel();
    }
    
    downloadFile(){
        const downloadUrl = this.Url + this.Files;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_blank';
        link.download = downloadUrl;
        link.click();
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
}
